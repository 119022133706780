import { Component } from '@angular/core';
import {MatIconButton, MatMiniFabButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {NgClass} from '@angular/common';
import {ViewService} from '../../../../shared/service/view.service';

@Component({
  selector: 'view-icons',
  standalone: true,
  imports: [
    // MatIconButton,
    MatIcon,
    NgClass,
    MatMiniFabButton,
    MatIconButton
  ],
  templateUrl: './view-icons.component.html'
})
export class ViewIconsComponent {
  constructor(public _view: ViewService) {
  }

}
