<mat-card style="height: 100%;cursor: pointer;" [routerLink]="routeLink ? routeLink :'./' + id"
  class="p-12 m-b-0 cardWithShadow">
  <div class="d-flex align-items-center justify-content-between">
    <div class="num">
      <p class="number m-b-0">{{ number }}</p>
      <p class="f-s-10 m-t-0">{{ createdDate ? (createdDate + ' UTC' | date:'medium') : '' }}</p>
    </div>

    <div class="d-flex gap-2">
      @for (statusItem of getStatusArray(); track statusItem) {
      <span [ngStyle]="{'background-color': statusItem['color']}" class="rounded f-w-600 text-white p-6 p-y-4 f-s-12">
        {{ statusItem.name }}
      </span>
      }
    </div>
  </div>
  <mat-divider></mat-divider>
  @for (row of details; track row.key;let first = $first) {
  @if (!first) {
  <mat-divider></mat-divider>
  }
  <div class="d-flex flex-row p-y-4 p-x-10">
    <p class="f-w-700">{{ row.key| translate }}&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p>{{row.value | truncate:[30, '...']}}</p>
  </div>
  }
  @if (content) {
  <mat-divider></mat-divider>
  <div class="d-flex flex-row p-y-4 p-x-10 justify-content-between">
    <ng-content></ng-content>
  </div>
  }
</mat-card>